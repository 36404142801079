import React from 'react';
import '../style/ThingsContent.css'; // Import the external CSS file

function ThingsContent() {
    return (
        <div className="container"> 
            <h2>Some things I believe in</h2>
            <p className="date">08/28/2024</p> 

            <h3 className='sc1'>Counterintuitive Truth</h3>
            <ol>
                <li>Things are too big to fall until they aren’t, so don’t assume the risk-free nature just because everyone else thinks so. Russia defaulted on its debt in 1998. The Roman Empire fell in 476 AD.</li>
                <li>Good opportunities are more likely to lie in unexpected places than places that are already hyped up and crowded. If everyone thinks it’s good, it’s probably too late. External doubts are the mother of unexpected returns. Proactivity, curiosity, and independent judgment pay off. "There is a pleasure in the pathless woods."</li>
                <li>It’s easy to confuse risk and Sharpe ratio. Seemingly low-risk, low-return options often carry more implicit risk because the world is a constantly changing, high-volatility place. The riskiest thing is not taking enough calculated risks when you can.</li>
                <li>The most mature and effective people keep a child’s spirit alive in their hearts.</li>
                <li>Our perception of the world is malleable. Repeated exposure to things that don’t make sense requires extra consciousness to spot inconsistencies. This is harder than it seems.</li>
            </ol>
            
            <h3 className='sc2'>Values & Others</h3>
            <ol>
                <li>The time, talent, opportunity, capital, and resources to create something others will one day use is a privilege. Treat it with the respect it deserves.</li>
                <li>When you have clear life goals, every second can be optimized—even the exploratory phases.</li>
                <li>Building, writing, coding, creating, and learning are the five salvations of the 21st century that most people with internet access can pursue.</li>
                <li>If today I wake up healthy, have access to a safe home, healthy food, a hot shower, a place to work out, and the internet—that’s a lot to be grateful for, and I shall make the most out of my day.</li>
                <li>Companies are as likely to be suffocated by too much capital as they are to die from running out of it. People who "have yet have not" in their childhood seem to unleash the most potential and carry the most drive. Feeling short or operating under stress is necessary for becoming smarter, stronger, and freer.</li>
                <li>Empathy is as vital and rare as intelligence. It’s essential to empathize with users, customers, employees, and investors. Putting yourself in their shoes creates real value. Only those who care can truly make an impact.</li>
                <li>Health, time, attention, and opportunities are things I likely don’t value enough.</li>
                <li>In-person work and internet friends are great.</li>
                <li>For things within your control, operationalization is underrated. Be specific about your approach to problems: thought process, next steps, progress, feedback loops, resources, time-boxed attempts, and expected returns. Specificity leads to more lessons, iterations, and opportunities.</li>
                <li>You’ll likely regret not trying something interesting more than you’ll regret trying it. But remember, life is short, so keep the bar for "interesting" high. Avoid FOMO on mediocre opportunities to make space for truly great things.</li>
                <li>Capitalism and markets are great. People can only coordinate well when their incentives are aligned. Protocol design for decentralized systems is the hyper-reality of this truth.</li>
                <li>You need an edge to win. Luck more often is earned than happened randomly. As Hemingway said, "It is better to be lucky. But I would rather be exact. Then when luck comes, you are ready."</li>
                <li>Knowledge compounds. Textbooks are awesome. The smartest people I know read advanced textbooks or learn hardcore subjects, sometimes with a grad student tutor, even in adulthood. More knowledge means new perspectives.</li>
                <li>Strong emotions are mind-killers. Things are never as good or as bad as they seem. Keep emotions within a 20% swing to maintain clarity. Avoid ego and fear at all costs.</li>
                <li>Optimists keep searching for breakthroughs, while pessimists give up.</li>
                <li>Be kind.</li>
            </ol>
        </div>
    );
}

export default ThingsContent;
