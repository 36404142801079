import React, { useEffect, useState } from 'react';

function TopLeftCorner({ setContent }) {
  console.log('setContent in TopLeftCorner:', setContent); // Debugging log

  const [currentTime, setCurrentTime] = useState(new Date());

  // Update the time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Format the time as "MM DD 24 HH MM SS"
  const month = String(currentTime.getMonth() + 1).padStart(2, '0');
  const day = String(currentTime.getDate()).padStart(2, '0');
  const hour = String(currentTime.getHours()).padStart(2, '0');
  const minute = String(currentTime.getMinutes()).padStart(2, '0');
  const second = String(currentTime.getSeconds()).padStart(2, '0');
  const formattedTime = `${month} ${day} 24 ${hour} ${minute} ${second}`;

  return (
    <div className="top-left-corner">
      <div
        className="name"
        style={{ cursor: 'pointer' }}
        onClick={() => setContent('about')}
      >
        <strong>Nico Pei</strong>
      </div>
      <p>{formattedTime}</p>
    </div>
  );
}

export default TopLeftCorner;
