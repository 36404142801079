import React, { useState, useEffect } from 'react'; 

function PhotosContent() {
    const [selectedImage, setSelectedImage] = useState(null);
  
    const photos = [
      { src: '/photography/arc.JPG', alt: 'arc of light' },
      { src: '/photography/bee1.JPG', alt: 'busy bee' },
      { src: '/photography/bird1.JPG', alt: 'happy bird' },
      { src: '/photography/blue1.JPG', alt: 'yellow moon' },
      { src: '/photography/chicken.jpg', alt: 'Chefchaouen' },
      { src: '/photography/blue2.JPG', alt: 'blue hour' },
      { src: '/photography/end1.jpg', alt: 'the end of the world' },
      { src: '/photography/end2.jpg', alt: 'the end pt.2' },
      { src: '/photography/halfmoon.jpg', alt: 'half moon rising' },
      { src: '/photography/horizon.JPG', alt: 'expansive horizon' },
      { src: '/photography/ufo1.JPG', alt: 'flying UFO' },
      { src: '/photography/ufo2.JPG', alt: 'mysterious UFO' },
    ];
  
    const openModal = (image) => {
      setSelectedImage(image);
    };
  
    const closeModal = () => {
      setSelectedImage(null);
    };
  
    return (
      <div className="photo-gallery">
        {photos.map((photo) => (
          <div
            className="photo-item"
            key={photo.src}
            onClick={() => openModal(photo.src)}
            style={{ backgroundImage: `url(${photo.src})` }}
          >
            <div className="photo-caption">{photo.alt}</div>
          </div>
        ))}
  
        {selectedImage && (
          <div className="modal" onClick={closeModal}>
            <span className="close">&times;</span>
            <img className="modal-content" src={selectedImage} alt="Selected" />
          </div>
        )}
      </div>
    );
  } 


export default PhotosContent; 