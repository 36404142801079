import React from 'react';

function DarkModeLayout() {
    return (
      <div className="dark-mode-page">
        <div className="infinite-time-tunnel">
          <div className="tunnel-container">
            <div className="tunnel-ring"></div>
            <div className="tunnel-ring"></div>
            <div className="tunnel-ring"></div>
            <div className="tunnel-ring"></div>
            <div className="tunnel-ring"></div>
            <div className="tunnel-ring"></div>
          </div>
          <h1 className="dark-mode-title"> time is the ultimate currency</h1>
        </div>
        {/* <div className="dark-mode-content">
          <p className="fun-text">now is the only time</p>
          <p className="fun-text">move fast, break things</p>
          <button className="fun-button">be useful</button>
        </div> */}
      </div>
    );
  } 

  export default DarkModeLayout; 